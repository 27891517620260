@import "colors";

input {
  &.form-control {
    border: none;
    border-radius: 0;
    padding-left: 20px;

    &:focus {
      border: none;
      box-shadow: none;
    }

  }
}

::placeholder {
  color: $color-form-placeholder!important;
}

.input-error-message {
  font-size: 15px;
  color: red;
}

@media (min-width: 576px) {
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333% !important;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50% !important;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667% !important;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333% !important;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25% !important;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667% !important;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333% !important;
  }

}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333% !important;
}

.col-6 {
  flex: 0 0 auto;
  width: 50% !important;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667% !important;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333% !important;
}

.col-3 {
  flex: 0 0 auto;
  width: 25% !important;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667% !important;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333% !important;
}
