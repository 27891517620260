h1 {
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
}

h2 {
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
}

h3 {
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
}

h4 {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
}

h5 {
  font-weight: normal;
  font-size: 28px;
  line-height: 40px;
}

h6 {
  font-weight: lighter;
  font-size: 24px;
  line-height: 24px;
}

body {
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: black;
  font-family: 'Poppins', sans-serif;
}
